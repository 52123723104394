<template>
    <div class="center">
        <h2>Carrinho vazio</h2>
    </div>

</template>
<script>
    export default{

    }
</script>
<style scoped>
    .center{
        margin: 0 auto;
    }
</style>