<template>
    <section class="sobre">
        <div class="box-content">
            <h2>Descubra o Mundo dos Perfumes Exclusivos</h2>
            <p>
                Bem-vindo ao nosso universo perfumado, onde a arte se encontra com
                a fragrância e a elegância se mistura com a essência. Aqui, exploramos
                uma coleção exclusiva de perfumes que são mais do que meras fragrâncias;
                eles são expressões de personalidade e estilo.
            </p>
        </div>
        <div class="box-content">
            <h2>Fragrâncias para Todas as Ocasiões</h2>
            <p>
                Nossa gama de perfumes é tão diversificada quanto as ocasiões em que
                você os usará. Desde fragrâncias leves e cintilantes perfeitas para o dia a
                dia até perfumes intensos e envolventes para noites memoráveis, temos algo
                para cada momento da sua vida.
            </p>
        </div>
        <div class="box-content">
            <h2>Ingredientes de Qualidade Superior</h2>
            <p>
                Trabalhamos com os melhores perfumistas e utilizamos ingredientes da mais alta
                qualidade para criar nossas fragrâncias. Cada perfume é uma sinfonia harmoniosa
                de notas cuidadosamente selecionadas, resultando em uma experiência olfativa única e duradoura.
            </p>
        </div>
        <div class="box-content">
            <h2>Perfumes Artesanais</h2>
            <p>
                Nossos perfumes são verdadeiras obras de arte. Cada frasco é cuidadosamente
                criado e projetado para se tornar um item de colecionador. Nossas fragrâncias
                são elaboradas em pequenos lotes para garantir a qualidade e a exclusividade de cada criação.
            </p>
        </div>
        <div class="box-content">
            <h2>Encontre Sua Assinatura Perfumada</h2>
            <p>
                Sua escolha de perfume é uma afirmação de sua individualidade.
                Nossa equipe de especialistas está à disposição para ajudá-lo a encontrar a
                fragrância que melhor representa você. Seja para um presente especial ou para
                o seu uso pessoal, estamos aqui para tornar a sua jornada perfumada memorável.
            </p>
        </div>
        <div class="box-content">
            <h2>Visite-nos em Nossa Boutique</h2>
            <p>
                Convidamos você a visitar nossa boutique e descobrir pessoalmente nossa coleção
                exclusiva de perfumes. Experimente diferentes fragrâncias, deixe-se envolver pelos
                aromas e encontre a sua assinatura perfumada. Venha conhecer o luxo e a sofisticação
                de nossos perfumes.
            </p>
        </div>
        <span>Este é apenas um texto fictício para uma página sobre perfumes.</span>
    </section>
</template>
<style>
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.sobre{
    margin-top: 20px;
    background-color: #f1f1f1;
}
.box-content{
    padding: 0 5px;
    text-align: justify;
}
.box-content h2{ 
    font-size: 18px;
    padding: 10px 0;
    text-align: center;
    color: #00A470;
}
.box-content p{
    line-height: 30px;
    font-size: 16px;
}
.sobre span{
    display: inline-block;
    padding: 20px 0;
    font-size: 20px;
    color: #00442F;
}
</style>
